export default function stripTypenames(obj: any, propToDelete: string): any {
  for (const property in obj) {
    if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
      delete obj.property;
      obj[property] = stripTypenames(obj[property], propToDelete);
    } else if (property === propToDelete) {
      delete obj[property];
    }
  }
  return obj;
}
