import config from '@src/config';
import { navigate } from 'gatsby';

export default {
  dashboard: (opts: any = {}, reload?: boolean) => {
    if (!reload) {
      navigate('/', opts);
      return;
    }
    const location = typeof window !== 'undefined' ? window.location : null;
    location && location.replace('/');
  },
  login: (opts?: any) => {
    navigate('/login', opts);
  },
  logout: () => {
    navigate('/logout');
  },
  notFound: () => {
    navigate('/404');
  },
  forbidden: () => {
    navigate('/403');
  },
  page: (path: string | null) => {
    const p = path || '';
    const location = typeof window !== 'undefined' ? window.location : null;
    location && location.replace(`/${p}`);
  },
  organizationSetting: () => {
    navigate('/team/domain-settings/');
  },
  handbookSetting: (handbookId: number) => {
    navigate(`/handbook/${handbookId}/settings`);
  },
  integration: () => {
    navigate('/integrations/');
  },
  manageEmployees: () => {
    navigate('/team/');
  },
  masterPolicies: () => {
    navigate('/internal/master-policies');
  },
  lookUps: (organizationId?: number) => {
    let eid = '';
    if (organizationId) {
      eid = btoa(organizationId.toString());
    }
    navigate(`/internal/lookups${eid && `?eid=${eid}`}`);
  },
  createHandbook: (folderId?: number) => {
    const url = folderId ? `/create?folderId=${folderId}` : '/create';
    navigate(url);
  },
  switchOrganization: (opts?: any) => {
    navigate('/switch-organization', opts);
  },
  // External Navigation
  editHandbook: (path: string) => {
    navigate(config.EDITOR_DOMAIN + path);
  },
  employeeHandbook: (path: string | null) => {
    let url = config.HANDBOOK_VIEWER_DOMAIN;
    if (path) {
      url += path;
    }

    navigate(url);
  },
};
