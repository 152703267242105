import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';

import ModalStyle from './style';
import closeIcon from '../../../images/icons/close_modal.svg';
import { ModalProps } from './index';

let modalResult = false;

function onEscKeyDown(e: any, setState: any): void {
  if (e.key !== 'Escape') return;
  setState({ fadeType: 'out' });
}

function closeModal(e: any, setState: any): void {
  e.preventDefault();
  setState({ fadeType: 'out' });
}

function transitionEnd(props: ModalProps, state: any): void {
  if (state.fadeType === 'in') {
    modalResult = false;
    props.noBackdrop && document.body.classList.add('overflow-hidden');
    return;
  }

  if (state.fadeType === 'out') {
    props.noBackdrop && document.body.classList.remove('overflow-hidden');
    props.onClose && props.onClose(modalResult);
  }
}

export const ModalV2: React.FC<ModalProps> = forwardRef(
  ({ children, id, className, noBackdrop, ...props }, ref) => {
    const [state, setState] = useState({ fadeType: '' });
    const onEscKeyDownHandler = (e: any) => onEscKeyDown(e, setState);
    id = id || `modal-${new Date().getTime()}`;

    useEffect(() => {
      window.addEventListener('keydown', onEscKeyDownHandler, false);

      if (props.isOpen) {
        setState({ fadeType: 'in' });
      } else {
        setState({ fadeType: 'out' });
      }

      return () => {
        window.removeEventListener('keydown', onEscKeyDownHandler, false);
      };
    }, [props.isOpen]);

    useImperativeHandle(ref, () => ({
      close: (result?: boolean) => {
        modalResult = result || false;
        setState({ fadeType: 'out' });
        document.body.classList.remove('overflow-hidden');
      },
    }));

    return (
      <ModalStyle
        id={id}
        className={`wrapper v2 fade-${state.fadeType} ${className || ''}`}
        role="dialog"
        onTransitionEnd={() => transitionEnd(props, state)}
      >
        <div className="box-dialog">
          <div className="modal-title font-avenirBook">Add your logo to the employee portal</div>
          <button onClick={(e) => closeModal(e, setState)} className="close">
            <img src={closeIcon} />
          </button>
          <div className="box-content-wrapper">
            <div className="box-content">{children}</div>
          </div>
        </div>
        {!noBackdrop && (
          <div
            className="background"
            onMouseDown={(e) => {
              closeModal(e, setState);
            }}
          />
        )}
      </ModalStyle>
    );
  }
);
