import axios from 'axios';
import config from '@src/config';
import { addActiveRequest, logFailedRequest, removeActiveRequest } from '@src/utils/logVault';

const apiUrl = `${config.API_SERVER_URL}/api/v1`;
const generatorApiUrl = `${config.API_AI_GENERATOR_URL}`;

const axiosInstance = axios.create({
  baseURL: apiUrl,
  withCredentials: true,
  headers: { 'Content-Type': 'multipart/form-data' },
});

// Request Interceptor
axiosInstance.interceptors.request.use((config) => {
  if (config.url) addActiveRequest(config.url);
  return config;
});

// Response Interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    if (response.config.url) removeActiveRequest(response.config.url); // Remove from active requests
    return response;
  },
  (error) => {
    const url = error.config?.url || 'Unknown URL';
    removeActiveRequest(url); // Ensure the failed request is removed
    logFailedRequest(url, {
      status: error.response?.status || 'Network Error',
      message: error.message,
      method: error.config?.method || 'Unknown Method',
      data: error.config?.data || null,
    });
    return Promise.reject(error);
  }
);

export default axiosInstance;

export const generatorApi = axios.create({
  baseURL: generatorApiUrl,
  headers: { 'Content-Type': 'application/json' },
});
